
import { Component, Vue, Prop } from 'vue-property-decorator';
import { JnService } from '@/services/jn.service';
import { EsService } from '@/services/es.service';
import { SearchData } from '@/models/search.model';
import { Event } from '@/models/event.model';
import moment from 'moment';
import MetaInfo from 'vue-meta';

@Component({
  metaInfo(this: JnAgenda): MetaInfo {
    return { title: 'JuraNews - Agenda' };
  },
})
export default class JnAgenda extends Vue {
  focus = '';
  type = 'month';
  today = moment().format('YYYY-MM-DD');
  typeToLabel = {
    month: 'Mese',
    week: 'Settimana',
    day: 'Giorno',
    '4day': '4 giorni',
  };
  start: Start | null = null;
  end: End | null = null;
  selectedEvent = {};
  selectedElement = null;
  selectedOpen = false;
  events: Event[] = [];

  async mounted() {
    this.events = await EsService.getEvents();
    this.calendarInstance.checkChange();
  }

  get title() {
    const { start, end } = this;
    if (!start || !end) {
      return '';
    }
    const startMonth = this.monthFormatter(start);
    const endMonth = this.monthFormatter(end);
    const suffixMonth = startMonth === endMonth ? '' : endMonth;
    const startYear = start.year;
    const endYear = end.year;
    const suffixYear = startYear === endYear ? '' : endYear;
    const startDay = start.day;
    const endDay = end.day;
    switch (this.type) {
      case 'month':
        return `${startMonth} ${startYear}`;
      case 'week':
      case '4day':
        return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
      case 'day':
        return `${startMonth} ${startDay} ${startYear}`;
    }
    return '';
  }
  get monthFormatter() {
    return this.calendarInstance.getFormatter({
      timeZone: 'UTC',
      month: 'long',
    });
  }

  viewDay({ date }: { date: string }) {
    this.focus = date;
    this.type = 'day';
  }
  getEventColor(event: Event) {
    return event.color;
  }
  setToday() {
    this.focus = this.today;
  }
  prev() {
    this.calendarInstance.prev();
  }
  next() {
    this.calendarInstance.next();
  }
  showEvent({ nativeEvent, event }: { nativeEvent: any; event: Event }) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => (this.selectedOpen = true), 10);
    };
    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }
    nativeEvent.stopPropagation();
  }

  async updateRange({ start, end }: { start: Start; end: End }) {
    this.events = await EsService.getEvents();
    this.start = start;
    this.end = end;
  }

  dayFormat(d: Start) {
    switch (d.weekday) {
      case 0:
        return 'D';
      case 1:
        return 'L';
      case 2:
        return 'M';
      case 3:
        return 'M';
      case 4:
        return 'G';
      case 5:
        return 'V';
      case 6:
        return 'S';
    }
  }

  private get calendarInstance(): Vue & {
    prev: () => void;
    next: () => void;
    checkChange: () => void;
    getFormatter: (format: any) => any;
  } {
    return this.$refs.calendar as Vue & {
      prev: () => void;
      next: () => void;
      checkChange: () => void;
      getFormatter: (format: any) => any;
    };
  }
}

export type Start = {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  weekday: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
};
export type End = {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  weekday: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
};
